
import { defineComponent, ref, computed } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import WizardWhiteBlock from '@/components/pages/auth/wizard/WizardWhiteBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WizardFormLine from '@/components/pages/auth/wizard/WizardFormLine.vue'
import PageStep from '@/components/layout/PageStep.vue'
import type { FlagCountry } from '@/definitions/shared/types'
import { wizardSteps } from '@/definitions/auth/data'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    TmAlert,
    WizardWhiteBlock,
    WizardFormLine,
    TmButton,
    PageStep,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const currentIndex = ref(2)
    const route = useRoute()
    const numberTypeValue = computed(() => route.params.numberType || 'toll-free')
    const numberType = ref(numberTypeValue.value)

    const countriesList = ref<FlagCountry[]>([
      {
        id: 'us',
        name: 'United States',
      },
    ])
    const numberList = ref([
      { number: '(844) 546-3435', info: '(2-way SMS & voice calls)' },
      { number: '(844) 546-3166', info: '(SMS)' },
      { number: '(844) 546-3941', info: '(Two-way SMS & Calls)' },
    ])
    const number = ref(numberList.value[0])

    const areaCode = ref('')
    const areaCodeList = ref([
      {
        label: '288 - California',
        numbers: [
          { number: '(288) 546-3435', info: '(2-way SMS & voice calls)' },
          { number: '(288) 546-3166', info: '(SMS)' },
          { number: '(288) 546-3941', info: '(Two-way SMS & Calls)' },
        ],
      },
      {
        label: '215 - Philadelphia',
      },
    ])
    const localNumber = ref('')

    const country = ref(countriesList.value[0])
    const yourPhone = ref()
    const registered = ref(true)
    const describe = ref('')

    return {
      isSmMax,
      wizardSteps,
      currentIndex,
      numberList,
      numberType,
      yourPhone,
      number,
      areaCode,
      areaCodeList,
      localNumber,
      countriesList,
      registered,
      describe,
      country,
    }
  },
})
